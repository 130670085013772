interface RGB {
    b: number;
    g: number;
    r: number;
}

/**
 * Calculates the YIQ value from RGB components to determine brightness
 * Formula: Y = (R * 299 + G * 587 + B * 114) / 1000
 * 
 * @param {RGB} rgb - The RGB color object
 * @return {number} The YIQ value (0-255, where higher means brighter)
 */
const rgbToYIQ = ({r, g, b}: RGB): number => (r * 299 + g * 587 + b * 114) / 1000;

/**
 * Converts a hex color string to RGB object
 * Supports both full (#RRGGBB) and shorthand (#RGB) hex formats
 * 
 * @param {string} hex - The hex color string (with or without # prefix)
 * @return {RGB | undefined} The RGB object or undefined if conversion fails
 */
const hexToRgb = (hex: string): RGB | undefined => {
    if (!hex || hex === undefined || hex === '') {
        return undefined;
    }

    // Remove # if present
    const cleanHex = hex.startsWith('#') ? hex.slice(1) : hex;
    
    // Handle shorthand hex (#RGB)
    if (cleanHex.length === 3) {
        const r = parseInt(cleanHex[0] + cleanHex[0], 16);
        const g = parseInt(cleanHex[1] + cleanHex[1], 16);
        const b = parseInt(cleanHex[2] + cleanHex[2], 16);
        return { r, g, b };
    }
    
    // Handle full hex (#RRGGBB)
    const result: RegExpExecArray | null = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(cleanHex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : undefined;
};

/**
 * Determines the optimal text color (black or white) based on the background color
 * to ensure readability and contrast
 * 
 * @param {string | undefined} colorHex - The background hex color
 * @param {number} threshold - YIQ threshold for determining text color (0-255)
 *                             Default 128 divides colors roughly in half
 *                             WCAG recommends values around 150-170 for better contrast
 * @param {string} darkColor - The color to use on light backgrounds (default: '#000')
 * @param {string} lightColor - The color to use on dark backgrounds (default: '#fff')
 * @return {string} The recommended text color for contrast
 */
const getTextContrastColor = (
    colorHex: string | undefined, 
    threshold: number = 128,
    darkColor: string = '#000',
    lightColor: string = '#fff'
): string => {
    if (!colorHex) return darkColor;
    
    // Check for valid hex format
    const isValidHex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(colorHex);
    if (!isValidHex) return darkColor;
    
    const rgb: RGB | undefined = hexToRgb(colorHex);
    if (rgb === undefined) return darkColor;
    
    return rgbToYIQ(rgb) >= threshold ? darkColor : lightColor;
};

export default getTextContrastColor;
